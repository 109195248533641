import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

export const GTCSignUp = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Check if the user has closed the popup before (cookie is set)
    const hasClosedPopup = Cookies.get('iframeFormClosed');
    if (hasClosedPopup) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  }, []);

  const handleClose = () => {
    // Set a cookie to mark that the popup was closed
    Cookies.set('iframeFormClosed', 'true');
    setIsVisible(false);
  };

  return (
    <div
      className={`fixed  bottom-0 overflow-hidden  top-0 left-0 right-0 flex items-center justify-center h-full w-full mx-auto bg-gray-900 bg-opacity-50 z-[1000] transform transition-all duration-600 ${
        isVisible ? 'translate-y-0' : 'translate-y-full'
      }`}>
      <div className="relative bg-white py-4 px-4 md:py-2 rounded-md shadow-lg h-[25vh] md:h-[180px] w-[95vw] md:w-[40vw] desk-container overflow-hidden">
        <button onClick={handleClose} className="absolute top-1 right-1 text-gtc1 bg-white rounded-full h-6 w-6 text-xl">
          <i role="presentation" className="fa-solid fa-times text-gtc1"></i>
        </button>
        {loading ? (
          <div className="w-full h-full z-50 overflow-hidden bg-gray-700 opacity-10 flex flex-col items-center justify-center col-span-2">
            <div className="loader ease-linear rounded-full border-4 border-t-4  h-12 w-12 mb-4"></div>
          </div>
        ) : (
          <></>
        )}
        <iframe
          id="acousticForm"
          title="GTC Website Form"
          width="100%"
          height="100%"
          src="https://content-us-9.content-cms.com/api/10dbe745-4a74-46eb-b156-63a0dca4d600/forms/652517953010335744"
          onLoad={() => setLoading(false)}
          className="overflow-hidden"></iframe>
      </div>
    </div>
  );
};
